<template>
    <div>
        <v-dialog
                v-model="showModal"
                max-width="1000"
                persistent
                v-if="showModal"
        >
            <v-card class="text-center appointment-form">
                <template v-if="errorMessage">
                    <v-alert
                            type="error"
                    >
                        {{ errorMessage }}
                    </v-alert>
                </template>
                <validation-observer v-slot="{ handleSubmit }" slim>
                    <v-card-title class="text-sm-left font-weight-bold d-flex align-center">
                        <template v-if="isCreate">
                            Tạo lịch hẹn
                        </template>
                        <template v-else-if="isUpdate">
                            Thông tin lịch hẹn
                        </template>
                        <template v-else>
                            Thực hiện lịch hẹn
                        </template>

                        <v-spacer />

                        <v-icon
                                aria-label="Close"
                                @click="hideModal"
                        >
                            mdi-close
                        </v-icon>
                    </v-card-title>
                    <v-card-text class="px-6 pb-0">
                        <v-row>
                            <v-col cols="4" class="pt-0 pb-0">
                                <v-menu
                                        ref="datePicker"
                                        v-model="datePicker"
                                        :close-on-content-click="false"
                                        :return-value.sync="appointment.dateTime"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <validation-provider
                                                v-slot="{ errors }"
                                                name="Ngày hẹn"
                                                :rules="isCalling ? '' : {required: true, after: afterDate,}"
                                                :immediate="false"
                                        >
                                            <v-text-field
                                                    style="margin-left: -4px"
                                                    v-model="appointment.dateTime"
                                                    color="primary"
                                                    label="Ngày hẹn *"
                                                    :error-messages="errors"
                                                    prepend-icon="mdi-calendar-outline"
                                                    readonly
                                                    v-on="on"
                                            />
                                        </validation-provider>
                                    </template>

                                    <v-date-picker
                                            v-model="appointment.dateTime"
                                            color="secondary"
                                            scrollable
                                            @change="$refs.datePicker.save(appointment.dateTime), datePicker = false"
                                            :min="currentDate"
                                            :readonly="isCalling"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0">
<!--                                <v-dialog-->
<!--                                        ref="hourPicker"-->
<!--                                        v-model="hourPicker"-->
<!--                                        :return-value.sync="appointment.hourTime"-->
<!--                                        width="290px"-->
<!--                                >-->
<!--                                    <template v-slot:activator="{ on }">-->
                                        <validation-provider
                                                v-slot="{ errors }"
                                                :rules="isCalling ? '' : {required: true, isHour: appointment.dateTime, afterHourTime: appointment.dateTime}"
                                                name="Giờ hẹn"
                                        >
                                            <v-text-field
                                                    v-model="appointment.hourTime"
                                                    label="Giờ hẹn *"
                                                    prepend-icon="mdi-calendar-outline"
                                                    :error-messages="errors"
                                            />
                                        </validation-provider>
<!--                                    </template>-->
<!--                                    <v-time-picker-->
<!--                                            v-if="hourPicker"-->
<!--                                            v-model="appointment.hourTime"-->
<!--                                            full-width-->
<!--                                            format="24hr"-->
<!--                                            :min="minHour"-->
<!--                                            :readonly="isCalling"-->
<!--                                    >-->
<!--                                        <div class="d-flex justify-space-between">-->
<!--                                            <v-btn small color="success" class="mr-0 ml-3" @click="$refs.hourPicker.save(appointment.hourTime)">Đồng ý</v-btn>-->
<!--                                            <v-btn small color="primary" class="ml-0 mr-3" @click="hourPicker = false">Hủy</v-btn>-->
<!--                                        </div>-->
<!--                                    </v-time-picker>-->
<!--                                </v-dialog>-->
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0">
                                <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                        name="Người phụ trách"
                                >
                                    <common-select-user
                                            class="appointment-personal"
                                            v-model="appointment.userId"
                                            :items="getSelectUser"
                                            :error-messages="errors"
                                            item-text="fullName"
                                            item-value="id"
                                            label="Người phụ trách *"
                                            color="primary"
                                            prependIcon="mdi-account"
                                            :readonly="isCalling"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="isCalling ? 8 : 12" class="pt-0 pb-0">
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Ghi chú"
                                        rules="max: 255"
                                >
                                    <v-textarea
                                            class="mb-2"
                                            rows="1"
                                            v-model="appointment.description"
                                            label="Ghi chú"
                                            :error-messages="errors"
                                            color="primary"
                                            prepend-icon="mdi-note"
                                            :readonly="isCalling"
                                    />
                                </validation-provider>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0" v-if="isCalling">
                                <validation-provider
                                        v-slot="{ errors }"
                                        class="mb-2"
                                        :rules="{required: true, is_not: oldStatusId}"
                                        name="Mối quan hệ"
                                >
                                    <v-autocomplete
                                            v-model="statusId"
                                            :items="contactStatusList"
                                            item-text="name"
                                            item-value="id"
                                            label="Mối quan hệ"
                                            prepend-icon="mdi-cast-connected"
                                            :error-messages="errors"
                                            eager
                                            return-object
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="border-right-scrollable">
                                <v-row>
                                    <v-col cols="6" class="text-sm-left font-weight-bold pl-3 pt-0">
                                        Thông tin khách hàng
                                    </v-col>
                                    <v-col cols="6" class="text-sm-right font-weight-bold pl-0 pt-0">
                                        <v-btn small v-if="checkAuthorization('order_create') && isCalling" @click="createOder(_.get(appointment, 'customer.contactId', ''))" color="warning">Tạo đơn hàng</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row style="overflow-x: hidden; overflow-y: auto; max-height: 285px">
                                    <v-col cols="12" class="py-0">
                                        <v-text-field
                                                v-model="appointment.fullName"
                                                label="Tên khách hàng"
                                                color="primary"
                                                readonly
                                                @click="clickContact(_.get(appointment, 'customer.contactId', ''))"
                                                class="i-cursor customer-name-readonly"
                                        />
                                        <!--                                    <span> ten khach hang</span>-->
                                        <!--                                    <a href="">{{ appointment.customer }}</a>-->
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <div class="d-flex align-center">
                                            <validation-provider
                                                    v-slot="{ errors }"
                                                    class="mb-2 w-100"
                                                    name="Số điện thoại"
                                                    :rules="{ regex: regex.isVNPhoneMobile, required: true }"
                                            >
                                                <v-text-field
                                                        readonly
                                                        v-model="appointment.mobile"
                                                        label="Số điện thoại *"
                                                        :error-messages="errors"
                                                        color="primary"
                                                        class="i-cursor customer-phone-readonly"
                                                        @click="clickContact(_.get(appointment, 'customer.contactId', ''))"
                                                />
                                            </validation-provider>

                                            <v-tooltip v-if="loadAppointmentSuccess && checkContactSources(appointment.contactSources)" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="icon-fb" icon small color="info" v-on="on"><v-icon>mdi-facebook-messenger</v-icon></v-btn>
                                                </template>
                                                <span>Nhắn tin</span>
                                            </v-tooltip>
                                            <v-menu offset-y v-if="loadAppointmentSuccess && isUpdate && appointment.contactType == 'main'">
                                                <template v-slot:activator="{ on: menu, attrs }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn
                                                            min-width="22px !important"
                                                            height="22"
                                                            class="btn-notification"
                                                            dark
                                                            small
                                                            v-bind="attrs"
                                                            v-on="{...tooltip, ...menu}"
                                                        >
                                                            <v-icon class="icon-flipped" size="16">mdi-phone</v-icon>
                                                        </v-btn>
                                                        </template>
                                                        <span>Thực hiện lịch hẹn</span>
                                                    </v-tooltip>
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        @click="showAppoimentAction(_.get(appointment, 'mobile', ''))"
                                                        class="main-mobile"
                                                    >
                                                        <v-list-item-title>{{_.get(appointment, 'mobile', '')}}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-for="(phone, index) in appointment.phoneRelated"
                                                        :key="index"
                                                        @click="showAppoimentAction(phone)"
                                                        class="sub-mobile"
                                                    >
                                                        <v-list-item-title>{{phone}}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip v-else-if="loadAppointmentSuccess && isUpdate" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="icon-call btn-notification" dark small v-on="on" @click="showAppoimentAction(_.get(appointment, 'mobile', ''))"><v-icon class="icon-flipped">mdi-phone</v-icon></v-btn>
                                                </template>
                                                <span>Thực hiện lịch hẹn</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>
                                    <v-row v-for="(child, index) in appointment.childCustomer" :key="index" class="px-3">
                                        <v-col cols="12" class="py-0" style="text-align: left"><v-icon size="18">mdi-account</v-icon> Thông tin con {{index > 0 ? (index + 1) : ''}}</v-col>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                    v-model="child.fullName"
                                                    label="Tên con"
                                                    color="primary"
                                                    readonly
                                                    class="i-cursor"
                                            />
                                        </v-col>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                    v-model="child.birthdayDescription"
                                                    label="Độ tuổi của con"
                                                    color="primary"
                                                    readonly
                                                    class="i-cursor"
                                            />
                                        </v-col>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                    v-model="child.productDescription"
                                                    label="Khóa học đăng ký"
                                                    color="primary"
                                                    readonly
                                                    class="i-cursor"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <validation-observer v-slot="{ handleSubmit }">
                                    <v-row style="margin-top: -30px">
                                        <v-col cols="12" md="12" style="text-align: right;">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Thông tin ghi chú"
                                                :rules="{required: reloadCreateNote, max:255}"
                                            >
                                                <v-textarea
                                                    label="Nhập ghi chú"
                                                    rows="3"
                                                    v-model="note"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                            <v-btn
                                                class="d-flex"
                                                small
                                                @click.prevent="handleSubmit(clickCreateHistoryContact)"
                                                color="primary"
                                                style="float: left"
                                            >Tạo</v-btn>
                                        </v-col>
                                        <v-tabs
                                            v-model="tab"
                                            grow>
                                        <v-tabs-slider></v-tabs-slider>

                                        <v-tab
                                            v-for="(item, key) in tabs"
                                            :key="key"
                                        >
                                            {{item}}
                                        </v-tab>
                                        
                                            <v-tab-item
                                                v-for="(item, key) in tabs"
                                                :key="key">
                                                <v-col cols="12" md="12" class="pt-0 mt-4 contact-history-table--wrapper">
                                                <contact-history-table 
                                                    :contactHistorys="contactHistorys" 
                                                    :showSTT="false" 
                                                    @deleteContactHistory="deleteContactHistory" 
                                                    :currentUserId="currentUserId"
                                                    @updateContactHistory="updateContactHistory"
                                                    :tab="parseInt(tab)"/>
                                                </v-col>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-row>
                                </validation-observer>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-row>
                        <v-col cols="3" style="padding:12px 0px 0px">
                            <v-btn
                                    v-if="isCalling"
                                    color="warning"
                                    class="reduce-height-btn"
                                    :disabled="appointment.status !== appointmentStatus.PLANNING"
                                    @click="newAppointment()"
                            >
                                Tạo lịch hẹn mới
                            </v-btn>
                        </v-col>
                        <v-col cols="6" style="padding: 0px">
                            <v-card-actions class="group-btn--form pt-3" v-if="isCalling" style="max-width: 350px">
                                <v-row>
                                    <v-col cols="6" class="pa-0">
                                        <v-btn
                                                color="success"
                                                :disabled="appointment.status !== appointmentStatus.PLANNING"
                                                @click.prevent="handleSubmit(changeSelectStatus)"
                                                class="reduce-height-btn"
                                                style="margin-right: -10px"
                                        >
                                            Hoàn thành
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" class="pa-0">
                                        <v-btn
                                                color="primary"
                                                :disabled="appointment.status !== appointmentStatus.PLANNING"
                                                @click="hideModal"
                                                class="reduce-height-btn"
                                                style="padding: 12px !important"
                                        >
                                            Chưa hoàn thành
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                            <v-card-actions class="group-btn--form pt-3" v-else>
                                <v-row>
                                    <v-col cols="6" class="pa-0">
                                        <v-btn
                                                color="success"
                                                @click.prevent="handleSubmit(saveAppointment)"
                                                :disabled="!isCreate && appointment.status !== appointmentStatus.PLANNING"
                                                class="reduce-height-btn"
                                                style="margin-right: -10px"
                                        >
                                            Lưu lại
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" class="pa-0">
                                        <v-btn
                                                color="primary"
                                                @click="hideModal"
                                                class="reduce-height-btn"
                                        >
                                            Đóng
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-col>
                        <v-col cols="3">
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import TokenService from '../../../helpers/token';
    import ContactHistoryTable from '../../contact/components/ContactHistoryTable';
    import { contactCreator } from '../../../helpers/constants';
    import { regex, appointmentStatus } from '../../../helpers/constants'
    import * as moment from 'moment'
    import { showFbMessengerIcon } from '@/helpers/utils'
    import RepositoryFactory from '../../../repositories/RepositoryFactory'
    const AppointmentRepository = RepositoryFactory.get('appointment')
    const DepartmentRepository = RepositoryFactory.get('department');
    const ContactRepository = RepositoryFactory.get('contact');
    const ContactStatusRepository = RepositoryFactory.get('contact_status');

    export default {
        components: {ContactHistoryTable},
        data () {
            return {
                dataAppointment: {},
                regex,
                hourPicker: false,
                datePicker: false,
                isCreate: true,
                isUpdate: false,
                isCalling: false,
                errorMessage: '',
                showModal: false,
                appointment: {},
                user: [],
                loadAppointmentSuccess: false,
                appointmentStatus,
                from: '',
                currentUserId: '',
                tabs: ['Trao đổi', 'Log'],
                tab: 0,
                loading: false,
                totalItem: 0,
                contactHistorys: [],
                note: '',
                reloadCreateNote: false,
                contactStatusList: [],
                statusId: '',
                oldStatusId: ''
            }
        },
        async created () {
            this.getUsers()
            this.currentUserId = await TokenService.getId()
            Bus.$on('appointment-show-form-from-contact', async (contact) => {
                if (contact.mainId) {
                    let self = this
                    this.$swal({
                        title: 'Số điện thoại đã được gộp vào contact ' + contact.mainMobile + '.<br>Vui lòng thực hiện các thao tác tại contact chính.',
                        confirmButtonText: 'Xem contact chính',
                        showCancelButton: true,
                        cancelButtonText: 'Quay lại'
                    }).then((res) => {
                        if (res.value) {
                            let routeData = self.$router.resolve({
                                name: 'ContactDetail',
                                params: {
                                    id: contact.mainId || 0
                                }
                            })
                            window.open(routeData.href, '_blank')
                        }
                    })
                } else {
                    this.isCreate = true
                    this.isUpdate = false
                    this.isCalling = false
                    this.showModal = true
                    await this.getContactDetail(contact)
                    this.getContactHistory()
                }
            })
            Bus.$on('appointment-show-form-from-appointment', async (appointment) => {
                if (appointment.mainId) {
                    this.$swal({
                        title: 'Số điện thoại đã được gộp vào contact ' + appointment.mainMobile + '.<br>Vui lòng thực hiện các thao tác tại contact chính.',
                        confirmButtonText: 'Xem contact chính',
                        showCancelButton: true,
                        cancelButtonText: 'Quay lại'
                    }).then((res) => {
                        if (res.value) {
                            let routeData = this.$router.resolve({
                                name: 'ContactDetail',
                                params: {
                                    id: appointment.mainId || 0
                                }
                            })
                            window.open(routeData.href, '_blank')
                        }
                    })
                } else {
                    this.showModal = true
                    if (appointment.id) {
                        this.appointment = _.cloneDeep(appointment)
                        this.isCreate = false
                        this.isUpdate = true
                        this.isCalling = false
                        await this.getAppointmentDetail()
                        this.getContactHistory()
                    }
                }
            })
            Bus.$on('confirm-appointment-show-form', () => {
                this.showModal = true
            })
            Bus.$on('appointment-show-form-from-action', async (appointment) => {
                if (!regex.isVNPhoneMobile.test(appointment.mobile)) {
                    this.$swal({
                        title: 'Số điện thoại không đúng định dạng.',
                        width: 500
                    })
                    return
                }
                if (appointment.mainId) {
                    this.$swal({
                        title: 'Số điện thoại đã được gộp vào contact ' + appointment.mainMobile + '.<br>Vui lòng thực hiện các thao tác tại contact chính.',
                        confirmButtonText: 'Xem contact chính',
                        showCancelButton: true,
                        cancelButtonText: 'Quay lại'
                    }).then((res) => {
                        if (res.value) {
                            let routeData = this.$router.resolve({
                                name: 'ContactDetail',
                                params: {
                                    id: appointment.mainId || 0
                                }
                            })
                            window.open(routeData.href, '_blank')
                        }
                    })
                } else {
                    this.showModal = true
                    if (appointment.id) {
                        this.appointment = _.cloneDeep(appointment)
                        this.isCreate = false
                        this.isUpdate = false
                        this.isCalling = true
                        await this.getAppointmentDetail()
                        this.getContactStatusList()
                        this.getContactHistory()
                        let callDetail = {
                            ...appointment,
                            contactId: appointment.customer.contactId
                        }
                        Bus.$emit('voice-call-show-popup', callDetail)
                    }
                }
            })
        },
        beforeDestroy() {
          Bus.$off('appointment-show-form')
          Bus.$off('confirm-appointment-show-form')
        },
        computed: {
            currentDate () {
                return moment().format('YYYY-MM-DD')
            },
            minHour () {
                // kiem tra dateTime > ngay hien tai => min
                if(moment(this.appointment.dateTime).isAfter(moment())) {
                    return '00:00'
                } else {
                    return moment().subtract(-30, "minutes").format('HH:mm')
                }
            },
            afterDate () {
                return moment().subtract(1, "days").format('YYYY-MM-DD')
            },
            getSelectUser() {
                if (this.isAdmin() || this.checkAuthorization('appointment_view_all')) {
                    return this.users;
                }
                const dataUser = TokenService.getUser() || {};
                const userId = _.get(dataUser, 'id', '');
                if (this.isStaff()) {
                    return this.users.filter(data => data.id == userId);
                }
                return this.users.filter(data => data.departmentId === dataUser.departmentId  || dataUser.listDepartmentIds.includes(data.departmentId));
            },
        },
        watch: {
            tab: {
                handler() {
                    this.getContactHistory()
                }
            }
        },
        methods: {
            executeCall (appointment) {
                this.mobile = appointment.mobile
                if(!this.isCreate){
                    this.appointmentId = appointment.id
                    this.dataAppointment = {
                        customer: _.get(appointment, 'customer', ''),
                        id: _.get(appointment, 'id', ''),
                    }
                }
                const data = {
                    mobile: appointment.mobile,
                    appointmentId: this.isCreate ? null : appointment.id,
                    dataAppointment: this.isCreate ? {} : {
                        customer: _.get(appointment, 'customer', ''),
                        id: _.get(appointment, 'id', ''),
                    },
                    showCreateAppointment: true,
                    contactId: this.appointment.customer.contactId
                }
                Bus.$emit('voice-call-show-popup', data)
            },
            async createOder(contactId) {
                if (this.isCalling) {
                    await this.changeSelectStatus()
                }
                this.showModal = false
                const id = contactId
                this.$router.push({
                    name: 'OrderCreate',
                    params: { id: id }
                });
            },
            clickContact(contactId) {
                let routeData = this.$router.resolve({name: 'ContactDetail', params: { id: contactId || 0 }})
                window.open(routeData.href, '_blank')
            },
            async getUsers() {
                const loader = this.$loading.show();
                const respond = await DepartmentRepository.listUsers({
                    keyword: ''
                });
                loader.hide();
                if (_.get(respond, 'success', false)) {
                    const departmentList = _.get(respond, 'data', []).filter(
                        d => _.get(d, 'users', []).length > 0
                    );
                    const groupUsers = [];
                    departmentList.forEach(department => {
                        const { users = [] } = department;
                        if (users.length) {
                            groupUsers.push({
                                header: department.name,
                                name: _.get(department, 'name'),
                                departmentId: department.id,
                            });
                            users.forEach(user => {
                                groupUsers.push({
                                    fullName: _.get(user, 'fullName'),
                                    group: _.get(department, 'name'),
                                    id: user.id,
                                    avatar: _.get(user, 'avatar.filePath'),
                                    departmentId: department.id,
                                });
                            });
                        }
                    });
                    this.users = groupUsers;
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Lỗi !',
                        text: _.get(respond, 'message', '')
                    });
                }
            },
            hideModal: function () {
                if (this.$route.query.backPage) {
                    return this.$router.go(-1);
                }
                this.showModal = false
                this.isCreate = true
                this.errorMessage = ''
            },
            async getAppointmentDetail () {
                this.loadAppointmentSuccess = false
                const loader = this.$loading.show();
                const response = await AppointmentRepository.detail(this.appointment.id)
                if (response.success) {
                    this.appointment = _.get(response, 'data', {});
                    this.appointment.customer = _.get(response, 'data.customer', '');
                    this.appointment.fullName = _.get(response, 'data.customer.fullName', '');
                    this.appointment.userId = _.get(response, 'data.userId', '');
                    this.appointment.dateTime = moment(this.appointment.date).format('YYYY-MM-DD')
                    this.appointment.hourTime = moment(this.appointment.date).format('HH:mm')
                    // this.appointment.contactSources = Array.from(response.data.customer.contact.contactSources, item => item.name).join(", ")
                    const res = await ContactRepository.detail(_.get(response, 'data.customer.contactId', ''))
                    if (res.success) {
                        if (res.data.customers) {
                            this.loadAppointmentSuccess = true
                            this.appointment.childCustomer = res.data.customers.filter(child => child.isMainCustomer == false && child.position == "child")
                            this.appointment.contactType = res.data.contactType
                            this.appointment.contactSources = res.data.contactSources.map(item => item.name).join(", ")
                            this.appointment.contactId = res.data.id
                            this.appointment.fullName = _.get(response, 'data.customer.fullName', '')
                            this.appointment.phoneRelated = res.data.phoneRelated
                        }
                        loader.hide();
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Lỗi !',
                            text: _.get(res, 'message', '')
                        })
                    }
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Lỗi ! lihcj hẹn',
                        text: _.get(response, 'message', '')
                    })
                }
            },
            async saveAppointment () {
                var d = new Date();
                var seconds = (d.getSeconds()<10?'0':'') + d.getSeconds();
                if (this.isCreate) {
                    const appointment = {
                        date: (this.appointment.dateTime + ' ' + this.appointment.hourTime).concat(':' + seconds),
                        userId: this.appointment.userId,
                        mobile: this.appointment.mobile,
                        description: _.trim(this.appointment.description),
                    }
                    const loader = this.$loading.show()
                    const response = await AppointmentRepository.create(appointment)
                    loader.hide()
                    if (!response.success) {
                        this.errorMessage = _.get(response, 'message', '')
                    } else {
                        const userData = _.get(response, 'data.userData', {})
                        const newAppointment = _.get(response, 'data.newAppointment', {})
                        Bus.$emit('created-appointment-success', {
                            userData,
                            newAppointment
                        })
                        this.showModal = false
                        this.$swal({
                                title: 'Tạo lịch hẹn thành công',
                                confirmButtonText: 'Đồng ý',
                            },
                        ).then(() => {
                            if (this.$route.query.backPage) {
                                return this.$router.go(-1);
                            }
                            if (this.from == 'quickView') {
                                Bus.$emit('reset-fetch-quick-view-history')
                            }
                            Bus.$emit('appointment-list-reload')
                            Bus.$emit('reset-fech-notifications')
                            Bus.$emit('contact-status-list')
                        })
                    }
                }else {
                    const appointment = {
                        date: (this.appointment.dateTime + ' ' + this.appointment.hourTime).concat(':' + seconds),
                        userId: this.appointment.userId,
                        mobile: this.appointment.mobile,
                        description: _.trim(this.appointment.description),
                    }
                    const loader = this.$loading.show()
                    const response = await AppointmentRepository.update(this.appointment.id, appointment)
                    loader.hide()
                    if (!response.success) {
                        this.errorMessage = _.get(response, 'message', '')
                    } else {
                        this.showModal = false
                        this.$swal({
                                title: 'Cập nhật lịch hẹn thành công',
                                confirmButtonText: 'Đồng ý',
                            },
                        ).then(() => {
                            Bus.$emit('appointment-list-reload')
                            Bus.$emit('reset-fech-notifications')
                            Bus.$emit('contact-status-list')
                        })
                    }
                }
            },

            async getContactHistory() {
                const contactId = this.appointment.customer.contactId;
                let params = {
                    limit: 30,
                    page: 0
                }
                if (this.tab == 0) {
                    params.action = 'NOTE'
                } else {
                    params.ne_action = 'NOTE'
                }
                this.loading = true;
                const loader = this.$loading.show();
                const response = await ContactRepository.getContactHistorys(
                    params,
                    contactId
                );
                loader.hide();
                this.loading = false;
                if (response.success) {
                    this.contactHistorys = _.get(response, 'data', []).map(contact => {
                        const contactTemp = contact;
                        contactTemp.creatorFullName = _.get(
                            contactTemp,
                            'creator.fullName',
                            null
                        );
                        if (!contactTemp.creatorFullName) {
                            contactTemp.creatorFullName =
                            contactCreator[contact.createdBy] || '';
                        }
                        contactTemp.editable = false
                        return contactTemp;
                    });
                    this.totalItem = _.get(response, "data.total_item", 0);;
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Lỗi !',
                        text: _.get(response, 'message', '')
                    });
                    this.contactHistorys = [];
                    this.totalItem = 0;
                }
            },

            async clickCreateHistoryContact() {
                if (_.trim(this.note) == '') {
                    this.reloadCreateNote = true;
                    return;
                }
                const dataUpdate = {
                    contactId: this.appointment.customer.contactId,
                    description: _.trim(this.note)
                };
                let loading = this.$loading.show();
                const response = await ContactRepository.createContactHistory(dataUpdate, this.appointment.customer.contactId);
                loading.hide();
                if (response.error) {
                    this.errorMessage = _.get(response, 'message', '');
                    this.$swal({
                        type: 'error',
                        title: 'Lỗi !',
                        text: this.errorMessage,
                    }).then(() => {});
                    return;
                }
                this.note =  ' ';
                this.reloadCreateNote = false;
                this.getContactHistory();
            },

            async updateContactHistory(contactHistory) {
                // this.loading = this.$loading.show()
                let res = await ContactRepository.updateContactHistory(contactHistory.id, {'description': contactHistory.description})
                // this.loading.hide()
                if (res.success) {
                    this.getContactHistory()
                } else {
                    this.errorMessage = _.get(res, 'message', '');
                    this.$swal({
                        type: 'error',
                        title: 'Lỗi !',
                        text: this.errorMessage,
                    }).then(() => {})
                    this.getContactHistory()
                }
            },

            async deleteContactHistory (contactHistory) {
                this.$swal({
                    title: 'Xóa lịch sử',
                    text: 'Bạn có thực sự chắc chắn ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy bỏ',
                }).then(async (result) => {
                    if (result.value) {
                        const loader = this.$loading.show()
                        let res = await ContactRepository.deleteContactHistory(contactHistory.id)
                        loader.hide()
                        if (res.success) {
                            this.$swal({
                                title: 'Xóa thành công !',
                                confirmButtonText: 'Đồng ý',
                                },
                            ).then(() => {
                                this.getContactHistory()
                            })
                        } else {
                            this.$swal({
                                type: 'error',
                                title: 'Lỗi !',
                                text: _.get(res, 'message', ''),
                            })
                        }
                    }
                })
            },
            async getContactStatusList() {
                const loading = this.$loading.show();
                let store = 'T';
                if (_.get(this.appointment.customer.contact, 'contactStatus.type', 'T').includes('C')) {
                    store = 'C';
                }
                if (_.get(this.appointment.customer.contact, 'contactStatus.type', 'T').includes('G')) {
                    store = 'G';
                }
                if (_.get(this.appointment.customer.contact, 'contactStatus.type', 'T').includes('M')) {
                    store = 'M';
                }
                const response = await ContactStatusRepository.list({store});
                loading.hide();
                if (response.success) {
                  let notInTypeList = ['T4', 'T5', 'C4', 'C5', 'G4', 'G5'];
                    const contactStatusList = [];
                    _.get(response, 'data', []).forEach(contactStatus => {
                      if (notInTypeList.includes(contactStatus.type)) {
                        return ;
                      }
                        _.get(contactStatus, 'data', []).forEach(data => {
                            contactStatusList.push({
                                type: contactStatus.type,
                                id: _.get(data, 'id', 0),
                                name: _.get(data, 'name', 0),
                            });
                        });
                    });
                    const checkType = {};
                    contactStatusList.forEach(contactStatus => {
                        const typeContactStatus = contactStatus.type;
                        if (store.includes(typeContactStatus)) {
                            if (!checkType[typeContactStatus]) {
                                checkType[typeContactStatus] = true;
                                if (contactStatusList.length > 0) {
                                    contactStatusList.push({ divider: true });
                                }
                                contactStatusList.push({
                                    header: typeContactStatus,
                                    style: 'color: red;'
                                });
                            }
                            contactStatusList.push(contactStatus);
                        }
                    });
                    for (let i = 0; i < contactStatusList.length; i++) {
                        if (contactStatusList[i].header === 'T0' || contactStatusList[i].type === 'T0' 
                            || contactStatusList[i].header === 'C0' || contactStatusList[i].type === 'C0'
                            || contactStatusList[i].header === 'G0' || contactStatusList[i].type === 'G0') {
                            contactStatusList.splice(i, 1)
                        }
                    }
                    this.contactStatusList = _.cloneDeep(contactStatusList)
                    this.statusId = this.oldStatusId = contactStatusList.filter(status => status.id == this.appointment.customer.contact.contactStatus.id)[0]
                } else {
                    this.contactStatusList = [];
                }
            },
            async changeSelectStatus() {
                const dataUpdate = [
                    {
                        contactId: this.appointment.customer.contactId,
                        statusId: this.statusId.id
                    }
                ];
                const loading = this.$loading.show();
                const response = await ContactRepository.updateContactStatus(dataUpdate);
                loading.hide();
                if (response.error) {
                    this.$swal({
                        type: 'error',
                        title: 'Lỗi !',
                        text: _.get(response, 'message', ''),
                    })
                } else {
                    const loader = this.$loading.show()
                    const response = await AppointmentRepository.updateStatus(this.appointment.id, {status: 'done'})
                    loader.hide()
                    if(response.error){
                        this.$swal({
                            type: 'error',
                            title: 'Lỗi !',
                            text: _.get(response, 'message', ''),
                        })
                    } else {
                        Bus.$emit('appointment-list-reload')
                        Bus.$emit('reset-fech-notifications')
                        Bus.$emit('contact-status-list')
                        this.showModal = false
                        this.statusId = {}
                    }
                }
            },
            async newAppointment() {
                await this.changeSelectStatus()
                Bus.$emit('appointment-show-form-from-contact', this.appointment)
            },
            showAppoimentAction(phone) {
                let appointmentDetail = _.cloneDeep(this.appointment)
                appointmentDetail.mobile = phone
                Bus.$emit('appointment-show-form-from-action', appointmentDetail)
            },
            async getContactDetail(contact) {
                this.loadAppointmentSuccess = false
                const loading = this.$loading.show()
                const res = await ContactRepository.detail(_.get(contact, 'contactId', ''))
                loading.hide()
                if (res.success) {
                    const dataUser = TokenService.getUser() || {};
                    const id = dataUser.id
                    this.appointment = {
                        mobile: _.get(contact, 'mobile', ''),
                        fullName: _.get(contact, 'fullName', ''),
                        customer: {
                            contactId: _.get(contact, 'contactId', ''),
                        },
                        userId: id,
                        contactType: _.get(contact, 'contactType', ''),
                        contactSources: _.get(contact, 'contactSources', ''),
                        childCustomer: res.data.customers.filter(child => child.isMainCustomer == false && child.position == "child")
                    }
                    this.loadAppointmentSuccess = true
                }
            },
            checkContactSources(contactSources) {
                return showFbMessengerIcon(contactSources)
            }
        }
    }
</script>
<style scoped>
/deep/.appointment-personal .v-chip--select{
    padding: 0;
    height: 20px;
    margin: 0;
    border-radius: 0;
}
/deep/.appointment-personal .v-select__selections{
    min-height: 20px;
}
.border-right-scrollable {
    border-right: 1px solid #d2d2d2;
    overflow-y: auto;
    overflow-x: hidden;
}
.contact-history-table--wrapper {
    max-height: 160px;
    min-height: 160px;
    overflow: auto;
    }

.customer-name-readonly input {
    color: 	#00BFFF	
}

/deep/ .v-tabs-bar {
    height: 30px;
}
/deep/ .v-tab {
    font-size: 12px;
}
/deep/ table th {
  font-size: 12px !important;
}
/deep/ table td {
  font-size: 12px !important;
}
</style>
