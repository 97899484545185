<template>
    <v-dialog
            v-model="showModal"
            max-width="60%"
            v-if="showModal">
        <v-card>
            <v-card-title class="pt-0">
                <v-icon
                    aria-label="Close"
                    @click="hideModal"
                    style="position: absolute; right: 15px"
                >
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text class="pt-0">
                <v-row>
                    <v-col cols="12" sm="4" class="pt-0">
                        <div class="text-center count-sms-header">
                            Kết quả
                        </div>
                        <div class="count-sms-container text-center">
                            <div class="text-center number-wrapper">
                                <div class="count-number-success">
                                    {{ history.numberOfContact }}
                                </div>
                            </div>
                            <div class="count-label">
                                Tổng số contact đã gửi
                            </div>
                        </div>
                        <div 
                                class="count-sms-container text-center icon-pointer"
                                @click="showListContact('success')">
                            <div class="text-center number-wrapper">
                                <div class="count-number-success">
                                    {{ history.countSuccess }}
                                </div>
                            </div>
                            <div class="count-label">
                                Contact gửi thành công
                            </div>
                        </div>
                        <div 
                                class="count-sms-container text-center icon-pointer"
                                @click="showListContact('failed')">
                            <div class="text-center number-wrapper-failed">
                                <div class="count-number-failed">
                                    {{ history.countFailed }}
                                </div>
                            </div>
                            <div class="count-label">
                                Contact gửi thất bại
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="pt-0">
                        <div class="text-center count-sms-header">
                            Thông tin chi tiết
                        </div>
                        <v-row class="text-left send-sms-detail-container">
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                Thời gian gửi:
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                {{ history.createdAt }}
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                Tệp khách hàng:
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                {{ history.contactFilterTitle }}
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                Người gửi:
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                {{ showCreatedBy(history.createdBy) }}
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                Kênh gửi:
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                {{ history.channel }}
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                Mã tin nhắn:
                            </v-col>
                            <v-col cols="12" sm="6" class="sms-detail-text">
                                {{ history.smsCode }}
                            </v-col>
                        </v-row>
                        <v-row class="text-center" v-if="history.countFailed > 0 && checkSmsCode(history.smsCode)">
                            <v-btn 
                                    color="primary" 
                                    style="margin: 10px auto 0px; padding: 0px 20px !important" 
                                    height="30px"
                                    @click="reSendSms()">
                                <v-icon>mdi-telegram</v-icon> Gửi lại các contacts thất bại
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <result-detail-dialog />
    </v-dialog>    
</template>

<script>
import ResultDetailDialog from "./ResultDetailDialog"
import RepositoryFactory from "@/repositories/RepositoryFactory"
const SmsRepository = RepositoryFactory.get("sms")
const CustomerFileRepository = RepositoryFactory.get("customer_file")
const DepartmentRepository = RepositoryFactory.get("department")

export default {
    components: { ResultDetailDialog },
    props: {},
    data() {
        return {
            showModal: false,
            history: {},
            users: []
        }
    },
    created() {
        this.getUsers()
        Bus.$on("show-sms-send-result", (history) => {
            this.history = history
            this.showModal = true
            console.log(history)
        })
        Bus.$on("finish-send-sms", data => {
            this.showModal = false
            this.getDetailHistory(data.id)
        })
    },
    methods: {
        hideModal() {
            this.showModal = false
        },
        async getDetailHistory(id) {
            const loader = this.$loading.show()

            const res = await SmsRepository.detailHistory(id)
            if (res.success) {
                const response = await CustomerFileRepository.listResult(id)
                if (response.success) {
                    this.history = {
                        ..._.get(res, 'data', {}),
                        ..._.get(response, 'data', {})
                    }
                    this.showModal = true
                } else {
                    this.$swal({
                        title: "Lỗi !",
                        message: res.message
                    })
                }
            } else {
                this.$swal({
                    title: "Lỗi !",
                    message: res.message
                })
            }
            loader.hide()
        },

        async getUsers() {
            const loader = this.$loading.show();
            const respond = await DepartmentRepository.listUsers({
                keyword: ''
            });
            loader.hide();
            if (_.get(respond, 'success', false)) {
                const departmentList = _.get(respond, 'data', []).filter(
                    d => _.get(d, 'users', []).length > 0
                );
                const groupUsers = [];
                departmentList.forEach(department => {
                    const { users = [] } = department;
                    if (users.length) {
                        groupUsers.push({
                            header: department.name,
                            name: _.get(department, 'name'),
                            departmentId: department.id,
                        });
                        users.forEach(user => {
                            groupUsers.push({
                                fullName: _.get(user, 'fullName'),
                                group: _.get(department, 'name'),
                                id: user.id,
                                avatar: _.get(user, 'avatar.filePath'),
                                departmentId: department.id,
                            });
                        });
                    }
                });
                this.users = groupUsers;
            } else {
                this.$swal({
                    type: 'error',
                    title: 'Lỗi !',
                    text: _.get(respond, 'message', '')
                });
            }
        },

        showCreatedBy(value) {
            if (_.isArray(this.users) && this.users.length) {
                let index = this.users.findIndex(item => item.id == value)
                if (index > -1) {
                    return this.users[index].fullName
                }
            }

            return ""
        },

        reSendSms() {
            const params = {
                id: this.history.id,
                contactFilterId: this.history.contactFilterId,
                smsId: this.history.smsId,
                channel: this.history.channel
            }

            if (this.history.smsCode == 'SMS_ORDER_REMINDER') {
                
            } else if (this.history.smsCode == 'SMS_ORDER_T5_C5') {

            } else {
                const res = SmsRepository.resend(params)
            
                this.$swal({
                    title: "Tin nhắn đang được gửi đi, sẽ thông báo ngay khi kết thúc."
                })
            }
        },

        showListContact(type) {
            if (type == 'success') {
                if (this.history.countSuccess) {
                    let data = {resultData: this.history.success, isSuccess: true}
                    Bus.$emit("show-send-detail-sms", data)
                }
            } else {
                if (this.history.countFailed) {
                    let data = {resultData: this.history.failed, isSuccess: false}
                    Bus.$emit("show-send-detail-sms", data)
                }
            }

            return
        },

        checkSmsCode(value) {
            return !['SMS_ORDER_REMINDER', 'SMS_ORDER_T5_C5'].includes(value)
        }
    }
}
</script>

<style scoped>
.number-wrapper {
    border: 3px solid #4caf50;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 13px 0px;
    margin: auto;
}

.number-wrapper-failed {
    border: 3px solid #ff5252;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 13px 0px;
    margin: auto;
}

.count-number-success {
    font-size: 26px;
    font-weight: 400;
    color: #4caf50;
}

.count-number-failed {
    font-size: 26px;
    font-weight: 400;
    color: #ff5252;
}

.count-sms-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.count-label {
    font-size: 18px;
    font-weight: 400;
    margin: 5px 0px 15px;
}

.send-sms-detail-container {
    width: 80%;
    margin: auto;
}

.sms-detail-text {
    font-size: 17px;
    font-weight: bold;
}
</style>