<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="45">
    <v-icon class="mr-3 no-outline" size="24" @click="showDrawer()"
      >mdi-menu</v-icon
    >
    <v-icon class="mobile-show mr-3 no-outline" size="24" @click="returnHome()"
      >mdi-home</v-icon
    >

    <common-breadcrumb
      class="font-weight-light mobile-hidden"
      :breadcrumb="_.get($route, 'meta.breadcrumb', [])"
    />

    <v-spacer />

    <div class="mx-3" />

    <div
      class="announcement-container mobile-hidden d-flex"
      v-if="announcements.length"
      :style="'width:' + marqueeWidth"
    >
      <div style="width: 22px">
        <v-icon size="20px" style="padding-bottom: 2px; color: red"
          >mdi-bullhorn</v-icon
        >
      </div>
      <div
        class="announcement"
        @mouseenter="startScroll()"
        @mouseleave="stopScroll()"
      >
        <span
          v-for="(item, index) in announcements"
          :key="index"
          class="announcement-pack"
        >
          <span
            v-html="item.content"
            :class="
              item.link
                ? 'mr-5 announcement-text has-link'
                : 'mr-5 announcement-text'
            "
            @click="openLinkNotification(item.link)"
          >
            <!--          {{ item.content }}-->
          </span>
          <span class="mr-5" v-if="index + 1 < announcements.length"> | </span>
        </span>
      </div>
    </div>

    <v-spacer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      min-width="300"
      max-width="400"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2 mobile-hidden"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge color="red" overlap bordered>
            <template v-if="appointments.length" v-slot:badge>
              <span>{{ totalItem }}</span>
            </template>
            <template v-else v-slot:badge>
              <span>0</span>
            </template>
            <v-icon class="appbar-icon"> mdi-bell-badge-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <div class="notification-header">
            <h4>Thông báo</h4>
          </div>
          <div class="notification-height">
            <app-bar-item
              v-for="(notification, i) in appointments"
              :key="`item-${i}`"
              class="pa-0 ma-0"
            >
              <v-list-item-title>
                <div class="notification pa-0 ma-0">
                  <div
                    class="notification-action"
                    @click="openLinkNotification(notification.url)"
                  ></div>
                  <div class="notification-info">
                    <div class="notification-item" style="padding-top: 5px">
                      <span class="font-weight-bold">{{
                        _.get(notification, "title")
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="notification-item notification-item--des mt-2"
                    style="padding: 0px 10px 5px"
                  >
                    <div style="white-space: normal; overflow-wrap: break-word">
                      {{ _.get(notification, "description") }}
                    </div>
                  </div>
                </div>
              </v-list-item-title>
            </app-bar-item>
            <div
              class="text-center"
              v-show="appointments.length < 1 ? true : false"
            >
              <label>Không có thông báo nào</label>
            </div>
          </div>
          <template v-if="totalPage > currentPage">
            <v-btn @click="moreNotification()" color="primary" text> Xem thêm </v-btn>
          </template>
<!--          <template v-if="totalItem > 5">-->
<!--            <router-link :to="{ name: 'Appointment' }">-->
<!--              <v-btn color="primary" text> Xem thêm </v-btn>-->
<!--            </router-link>-->
<!--          </template>-->
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon class="appbar-icon">mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <!-- <app-bar-item to="/profile">
          <v-list-item-title v-text="'Trang cá nhân'" />
        </app-bar-item> -->
        <!-- <app-bar-item
          :class="
            checkAuthorization('order-ctv-create_read') &&
            !checkAuthorization('contact_read')
              ? 'hiden-person-result'
              : ''
          "
          to="/profile/report"
        >
          <v-list-item-title v-text="'Thành tích của tôi'" />
        </app-bar-item> -->
        <a class="d-block btn-logout" @click="goToProfile"> Trang cá nhân </a>
        <a class="d-block btn-logout" @click="logout"> Đăng xuất </a>
      </v-list>
    </v-menu>
    <video
      id="my-video"
      autoplay
      style="display: none"
      src="https://web1.caresoft.vn/images/320x240.ogg"
    ></video>
    <video
      id="peer-video"
      autoplay
      style="display: none"
      src="https://web1.caresoft.vn/images/320x240.ogg"
    ></video>
    <send-result-dialog />

    <appointment-form />
    <common-notification
      :hideNotifications="hideNotifications"
      :notificationPosition="notificationPosition"
      @closeNotification="closeNotification"
    />
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
import * as moment from "moment";
import handleSchedule from "@/mixins/handleSchedule";
// Utilities
import { mapState, mapMutations } from "vuex";
import TokenService from "../../helpers/token";
import { downloadFile } from "../../helpers/utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import AppointmentForm from "../../pages/appointment/components/AppointmentForm.vue";
import SendResultDialog from "../../pages/sms/sms-history/components/SendResultDialog";

const PAGE_LIMIT = 10;
const AppointmentRepository = RepositoryFactory.get("appointment");
const ContactRepository = RepositoryFactory.get("contact");
const NotificationRepository = RepositoryFactory.get("notification");
const UserRepository = RepositoryFactory.get("user");

// const socketConponent = socket

export default {
  name: "DashboardCoreAppBar",
  mixins: [handleSchedule],
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text primary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
    AppointmentForm,
    SendResultDialog,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dataAppointment: {},
    showConfirm: false,
    appointmentIds: [],
    profile: [{ title: "Profile" }, { divider: true }, { title: "Log out" }],
    totalItem: 0,
    appointments: [],
    hideNotifications: [],
    notificationPosition: [],
    remindContact: [],
    announcements: [],
    marqueeWidth: "60%",
    scrollAnnouncement: "",
    scrollLeft: 0,
    limit: 5,
    currentPage: 1,
    totalPage: 0
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  created() {
    this.fetchNotifications();
    const user = TokenService.getUser();
    const self = this;
    let socket = false;
    // if (_.isObject(user) && _.size(user)) {
    //   const username = _.get(user, "username");
    //   socket = require("socket.io-client")(
    //     process.env.VUE_APP_NOTIFICATION_SOCKET_URL,
    //     { path: process.env.VUE_APP_NOTIFICATION_SOCKET_PATH + "/socket.io" }
    //   );
    //   socket.on("connect", function () {
    //     socket.emit("user_join", { username: username });
    //   });
    //   socket.on("new_notifications", async function (data) {
    //     const block = _.get(data, "block", "0min");
    //     const newAppointment = _.get(data, "notification", {});
    //     const newAppointmentId = _.get(newAppointment, "id", 0);
    //     if (block === "0min") {
    //       self.addNewNotification(newAppointment);
    //     } else if (
    //       self.notificationPosition.indexOf(newAppointmentId) == -1 &&
    //       block == "5min"
    //     ) {
    //       const response = await AppointmentRepository.detail(
    //         newAppointmentId,
    //         {}
    //       );
    //       if (response.success) {
    //         let userPosition = TokenService.getPosition();
    //         let userId = TokenService.getId();
    //         let contact = response.data.customer.contact;
    //         if (
    //           contact.mainId ||
    //           (userPosition == "telesales" && userId != contact.userId)
    //         ) {
    //           return;
    //         }
    //         if (
    //           !self.hideNotifications.find(
    //             (data) => data.id == newAppointmentId
    //           )
    //         ) {
    //           self.notificationPosition.push(newAppointmentId);
    //           self.hideNotifications.push({
    //             ...response.data,
    //             showModal: true,
    //           });
    //         }
    //       }
    //     }
    //   });
    //   socket.on("import_contact_success", () => {
    //     console.log("import_contact_success.....");
    //     self
    //       .$swal({
    //         title: "Import dữ liệu thành công",
    //         confirmButtonText: "Đồng ý",
    //       })
    //       .then((res) => {
    //         if (res.value) {
    //           window.location.href = "/contact/contact-list";
    //         }
    //       });
    //   });
    //   socket.on("import_contact_failed", () => {
    //     console.log("import_contact_failed.....");
    //     self.$swal({
    //       title: "Lỗi!",
    //       text: "File upload còn contact chưa có link Url hoặc Nhóm sản phẩm để xác định nhóm ngành. Vui lòng kiểm tra lại.",
    //       confirmButtonText: "Đồng ý",
    //     });
    //   });
    //   socket.on("remain_appointments", async (data) => {
    //     if (
    //       self.remindContact.indexOf(data.time) == -1 &&
    //       (parseInt(data.notification.totalAppointments) > 0 ||
    //         parseInt(data.notification.totalContacts) > 0)
    //     ) {
    //       self
    //         .$swal({
    //           title:
    //             "Bạn còn " +
    //             data.notification.totalAppointments +
    //             " thông báo chưa thực hiện<br>và " +
    //             data.notification.totalContacts +
    //             " contact New chưa liên lạc.",
    //           confirmButtonText: "Xem danh sách thông báo",
    //           width: "520px",
    //         })
    //         .then((res) => {
    //           self.remindContact.push(data.time);
    //           if (res.value) {
    //             self.$router.push("/users/appointment");
    //           }
    //         });
    //     }
    //   });
    //   socket.on("export_contact_success", (res) => {
    //     self
    //       .$swal({
    //         title: "Export dữ liệu thành công",
    //         confirmButtonText: "Download",
    //       })
    //       .then(() => {
    //         downloadFile(_.get(res, "csvFile", ""), _.get(res, "fileName", ""));
    //       });
    //   });
    //   socket.on("export_order_success", (res) => {
    //     self
    //       .$swal({
    //         title: "Export dữ liệu thành công",
    //         confirmButtonText: "Download",
    //       })
    //       .then(() => {
    //         downloadFile(_.get(res, "csvFile", ""), _.get(res, "fileName", ""));
    //       });
    //   });
    //   socket.on("export_order_success_doisoat", (res) => {
    //     self
    //       .$swal({
    //         title: "Export dữ liệu đối soát thành công",
    //         confirmButtonText: "Download",
    //       })
    //       .then(() => {
    //         downloadFile(_.get(res, "csvFile", ""), _.get(res, "fileName", ""));
    //       });
    //   });
    //   socket.on("send-message-finish", (data) => {
    //     self
    //       .$swal({
    //         title: "Đã gửi tin nhắn đi thành công.",
    //         confirmButtonText: "Xem kết quả",
    //       })
    //       .then((res) => {
    //         if (res.value) {
    //           Bus.$emit("finish-send-sms", data);
    //         }
    //       });
    //   });
    //   socket.on("new_announcement", (res) => {
    //     let breadcrumb = document.getElementsByClassName(
    //       "container-breadcrumb"
    //     );
    //     if (breadcrumb.length) {
    //       self.marqueeWidth =
    //         (
    //           (window.innerWidth - breadcrumb[0].offsetWidth - 225) *
    //           0.8
    //         ).toFixed(0) + "px";
    //     } else {
    //       self.marqueeWidth = "60%";
    //     }
    //     self.announcements = res.announcement;
    //   });
    //   socket.on("export_account_report_success", (res) => {
    //     self
    //       .$swal({
    //         title: "Export dữ liệu thành công",
    //         confirmButtonText: "Download",
    //       })
    //       .then(() => {
    //         downloadFile(_.get(res, "csvFile", ""), _.get(res, "fileName", ""));
    //       });
    //   });
    //   Bus.$on("created-appointment-success", (data) => {
    //     socket.emit("created_appointment_success", {
    //       toUserName: _.get(data, "userData.username"),
    //       fromUserName: username,
    //       notification: _.get(data, "newAppointment"),
    //     });
    //   });
    //   socket.on("export_report_department_employee_success", (res) => {
    //     self
    //       .$swal({
    //         title: "Export dữ liệu thành công",
    //         confirmButtonText: "Download",
    //       })
    //       .then(() => {
    //         downloadFile(_.get(res, "csvFile", ""), _.get(res, "fileName", ""));
    //       });
    //   });
    // }
    Bus.$on("reset-fech-notifications", () => {
      this.fetchNotifications();
    });
  },

  beforeDestroy() {
    Bus.$off("reset-fech-notifications");
    Bus.$off("created-appointment-success");
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    closeModalCall() {
      this.showModal = false;
      this.mobile = "";
      this.appointmentId = 0;
    },

    // async updateAppointmentStatus(id, status) {
    //   const statusAppointment = {
    //     status: "done",
    //   };
    //   const response = await AppointmentRepository.updateStatus(
    //     id,
    //     statusAppointment
    //   );
    //   if (!response.success) {
    //     this.errorMessage = _.get(response, "message", "");
    //   } else {
    //     window.location.reload();
    //   }
    // },

    // executeCall(appointment) {
    //   const data = {
    //     mobile: appointment.mobile,
    //     appointmentId: appointment.id,
    //     dataAppointment: this.isCreate
    //       ? {}
    //       : {
    //           customer: _.get(appointment, "customer", ""),
    //           id: _.get(appointment, "id", ""),
    //         },
    //     showCreateAppointment: true,
    //   };
    //   Bus.$emit("voice-call-show-popup", data);
    // },

    // callCenter(notification) {
    //   const mobile = _.get(notification, "mobile", "");
    //   const appointmentId = _.get(notification, "id", 0);
    //   if (!mobile || !appointmentId) {
    //     return;
    //   }
    //   this.showModal = true;
    //   this.mobile = mobile;
    //   this.appointmentId = appointmentId;
    // },

    logout() {
      TokenService.removeRefreshToken();
      TokenService.removeToken();
      TokenService.removeUser();
      TokenService.removePermissions();
      this.$router.push("/login");
    },

    goToProfile() {
      this.$router.push("/profile");
    },

    goToPage(item) {
      if (!_.get(item, "link", "")) {
        return;
      }
      this.$router.push({
        name: _.get(item, "link", ""),
      });
    },

    resetCount() {
      this.countNews = 0;
    },

    // newAppointment() {
    //   Bus.$emit("appointment-show-form-from-contact", this.appointments);
    // },

    detailNotification(url) {
      this.$router.push(url);
    },

    // editAppointment(appointment) {
    //   let userPosition = TokenService.getPosition();
    //   let userId = TokenService.getId();
    //   let contact = appointment.customer.contact;
    //   if (userPosition == "telesales" && userId != contact.userId) {
    //     this.$swal({
    //       title: "Lỗi !",
    //       text: "Contact không thuộc quyền phụ trách.",
    //     });
    //   } else {
    //     const appointmentDetail = {
    //       id: appointment.id,
    //       mainId: contact.mainId,
    //       mainMobile: contact.phoneRelated[0],
    //       customer: {
    //         contactId: appointment.customer.contactId,
    //       },
    //     };
    //     Bus.$emit("appointment-show-form-from-appointment", appointmentDetail);
    //   }
    // },

    // addNewNotification(notification) {
    //   const id = parseInt(_.get(notification, "id", 0), 10);
    //   if (
    //     this.appointmentIds.indexOf(id) === -1 ||
    //     this.appointmentIds.length < 1
    //   ) {
    //     // add new
    //     this.appointments.splice(PAGE_LIMIT - 1);
    //     this.appointments.unshift(notification);
    //     this.appointmentIds.push(id);
    //     // this.countNews++
    //     this.totalItem++;
    //   }
    // },

    async fetchNotifications() {
      const currentUserId = await TokenService.getId();
      const response = await NotificationRepository.list({
        listUser: [currentUserId],
        // typeNoti: 1,
        page: this.currentPage,
        limit: this.limit
      });
      if (response.error) {
        console.log("error fetchNotifications NotificationRepository");
      } else {
        this.appointments = this.appointments.concat(_.get(response, "data.list_notification", []));
        this.totalItem = _.get(response, "data.total_item", 0);
        this.totalPage = Math.ceil(this.totalItem/this.limit);
      }
    },

    closeNotification(index) {
      this.hideNotifications[index].showModal = false;
      let key = this.notificationPosition.indexOf(
        this.hideNotifications[index].id
      );
      this.notificationPosition.splice(key, 1);
    },

    showDrawer() {
      Bus.$emit("change-drawer");
      // this.$store.commit("SET_DRAWER", true);
    },

    returnHome() {
      if (this.checkAuthorization("dashboard_read")) {
        this.$router.push("/dashboard");
      } else {
        // this.$router.push("contact/contact-list");
        this.$router.push("/dashboard");
      }
    },

    stopScroll() {
      this.scrollLeft = 0;
      clearInterval(this.scrollAnnouncement);
      let element = document.getElementsByClassName("announcement");
      if (element.length) {
        element[0].scrollTo({
          left: this.scrollLeft,
        });
      }
    },

    startScroll() {
      let element = document.getElementsByClassName("announcement");
      if (element.length) {
        this.scrollLeft = 0;
        this.scrollAnnouncement = setInterval(() => {
          this.scrollLeft += 2;
          element[0].scrollTo({
            left: this.scrollLeft,
          });
        }, 30);
      }
    },

    openLinkNotification(link) {
      if (link) {
        window.open(link, "_self");
      }
    },
    moreNotification() {
      this.currentPage ++;
      this.fetchNotifications();
    }
  },
  updated() {
    this.$nextTick(() => {
      $(".announcement-text p").css("margin-bottom", "0px");
    });
  },
};
</script>
<style lang="scss" scoped>
.notification-has-0 {
  .v-badge__badge {
    display: none;
  }
}
.notification-has-1 {
  .v-badge__badge {
    display: inline-block;
  }
}
.padding-10 {
  padding: 10px;
}
.v-messages__message {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.appbar-icon {
  font-size: 22px !important;
}
.theme--dark {
  .v-list-item__title {
    .notification {
      .notification-info {
        background-color: #e91e63;
        .notification-item {
          .v-icon {
            color: white;
          }
        }
      }
    }
  }
}
.notification-info {
  background-color: #f1f1f1;
  padding: 5px 10px;
  .notification-item {
    .v-icon {
      color: #a847bf;
    }
  }
}
.notification-header {
  padding: 5px 15px;
  color: #ff0000;
}
.no-outline:focus {
  outline: unset;
}
.hiden-person-result {
  display: none;
}
.announcement-container {
  display: flex;
  background-color: white;
  padding: 0px 10px;
  border-radius: 15px;
  .announcement {
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
    display: inline-flex;
    .announcement-pack {
      display: inline-flex;
      white-space: nowrap;
      .announcement-text {
        color: black;
        text-decoration: unset;
      }
      .announcement-text.has-link {
        cursor: pointer;
      }
      .announcement-text.has-link:hover {
        color: blue;
        text-decoration: underline;
        text-underline-position: below;
      }
    }
  }
}
.notification-height {
  overflow: auto;
  max-height: 440px;
}
</style>
