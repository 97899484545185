import * as moment from 'moment'

export default {
    methods: {
        getDayTimePeriodsMapping () {
          const periods = {
            morning: {
              name: 'Sáng',
              times: {
                '07:00': '7:00',
                '08:00': '8:00',
                '09:00': '9:00',
                '10:00': '10:00',
                '11:00': '11:00',
              },
              rowspan: 5,
            },
            afternoon: {
              name: 'Chiều',
              times: {
                '12:00': '12:00',
                '13:00': '13:00',
                '14:00': '14:00',
                '15:00': '15:00',
                '16:00': '16:00',
              },
              rowspan: 5,
            },
            evening: {
              name: 'Tối',
              times: {
                '17:00': '17:00',
                '18:00': '18:00',
                '19:00': '19:00',
                '20:00': '20:00',
                '21:00': '21:00',
              },
              rowspan: 5,
            },
          }
          console.log(periods)
          return periods
        },
        getOnlyDayTimePeriods () {
          const mapItems = this.getDayTimePeriodsMapping()
          const output = {}
          // const self = this
          _.forEach(mapItems, function (item) {
            _.forEach(item.times, function (timeValue, timeKey) {
              output[timeKey] = timeValue
            })
          })
          console.log(output)
          return output
        },
        getDayNames () {
          return ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'Chủ nhật']
        },
        getDayNameWithDateMapping (monday) {
          const output = {}
          const names = {}
          const nameHeaders = {}
          let currentDay = _.clone(monday)
          const dayNames = this.getDayNames()
          console.log(moment(currentDay).format('YYYY-MM-DD').toString())
          for (let i = 0; i < 7; i++) {
            if (i > 0) {
              currentDay = moment(currentDay).add(1, 'day')
            }
            names[moment(currentDay).format('YYYY-MM-DD').toString()] = dayNames[i]
            nameHeaders[moment(currentDay).format('YYYY-MM-DD').toString()] = {
              name: dayNames[i] + ' - ' + moment(currentDay).format('DD/MM').toString(),
              date: moment(currentDay).format('YYYY-MM-DD').toString(),
            }
          }
          output.names = names
          output.nameHeaders = nameHeaders
          // console.log(output)
          return output
        },
        getMonday (d) {
          d = new Date(d)
          const day = d.getDay()
          const diff = d.getDate() - day + (day === 0 ? -6 : 1)
          return new Date(d.setDate(diff))
        },
        getSunday (d) {
          const tmp = _.clone(d)
          return new Date(tmp.setDate(tmp.getDate() + 6))
        },
        getCurrentWeek (inputDate) {
          const monday = this.getMonday(inputDate)
          const sunday = this.getSunday(monday)
          const daynamesMapping = this.getDayNameWithDateMapping(monday)
          const start = moment(monday).format('YYYY-MM-DD').toString()
          const end = moment(sunday).format('YYYY-MM-DD').toString()
          const ouput = {
            start: start,
            end: end,
            daynamesMapping: daynamesMapping,
            dayTimePeriodsMapping: this.getDayTimePeriodsMapping(),
            weekName: this.getWeekName(start, end),
          }
          console.log(ouput)
          // this.getOnlyDayTimePeriods()
          // this.getDayTimePeriodsMapping()
          return ouput
        },
        getWeekName (start, end) {
          const dayStart = moment(start).format('DD').toString()
          const dayEnd = moment(end).format('DD').toString()
          let output = '' + moment(start).format('MMM').toString() + ' ' + dayStart + '-'
          output += dayEnd + ', ' + moment(end).format('YYYY').toString()
          return output
        },
        getDayOfWeek(currentDay) {
          let day = new Date(currentDay)
          switch (day.getDay()) {
            case 0:
              return 'Chủ nhật'
            case 1:
              return 'Thứ 2'
            case 2:
              return 'Thứ 3'
            case 3:
              return 'Thứ 4'
            case 4:
              return 'Thứ 5'
            case 5:
              return 'Thứ 6'
            case 6:
              return 'Thứ 7'
          }
        },
        getDayNameInMonth (start, end) {
          const output = {}
          const names = {}
          const nameHeaders = {}
          let currentDay = _.clone(start)
          for (let i = 0; i < end.getDate(); i++) {
            if (i > 0) {
              currentDay = moment(currentDay).add(1, 'day')
            }
            let day = this.getDayOfWeek(currentDay)
            names[moment(currentDay).format('YYYY-MM-DD').toString()] = day
            nameHeaders[moment(currentDay).format('YYYY-MM-DD').toString()] = {
              name: day + ' - ' + moment(currentDay).format('DD/MM').toString(),
              date: moment(currentDay).format('YYYY-MM-DD').toString(),
            }
          }
          output.names = names
          output.nameHeaders = nameHeaders
          // console.log(output)
          return output
        },
        getCurrentMonth (inputMonth) {
          const dateData = inputMonth.split("-")
          const month = parseInt(dateData[1]) - 1
          const year = dateData[0]
          const firstDate = new Date(year, month, 1)
          const lastDate = new Date(year, month + 1, 0)
          const daynamesMapping = this.getDayNameInMonth(firstDate, lastDate)
          const start = moment(firstDate).format('YYYY-MM-DD').toString()
          const end = moment(lastDate).format('YYYY-MM-DD').toString()
          const ouput = {
            start: start,
            end: end,
            daynamesMapping: daynamesMapping,
            dayTimePeriodsMapping: this.getDayTimePeriodsMapping(),
            weekName: this.getWeekName(start, end),
          }
          // this.getOnlyDayTimePeriods()
          // this.getDayTimePeriodsMapping()
          return ouput
        },
        checkIsTodayOrTomorrow(date) {
          let today = new Date()
          let tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          today = moment(today).format('YYYY-MM-DD')
          tomorrow = moment(tomorrow).format('YYYY-MM-DD')

          if (today == moment(date).format('YYYY-MM-DD')) {
            return moment(date).format('H:mm') + ' - Hôm nay'
          }
          
          if (tomorrow == moment(date).format('YYYY-MM-DD')) {
            return moment(date).format('H:mm') + ' - Ngày mai' 
          }

          return moment(date).format('H:mm - DD/MM/YYYY')
        },
        checkAppointmentTime(date) {
          let today = new Date()
          let tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          let todayWithTime = moment(today).format('YYYY-MM-DD HH:mm')
          today = moment(today).format('YYYY-MM-DD')
          tomorrow = moment(tomorrow).format('YYYY-MM-DD')

          if (todayWithTime > moment(date).format('YYYY-MM-DD HH:mm')) {
            return 'Lỡ lịch hẹn'
          }

          if (today == moment(date).format('YYYY-MM-DD')) {
            return moment(date).format('H:mm') + ' - Hôm nay'
          }
          
          if (tomorrow == moment(date).format('YYYY-MM-DD')) {
            return moment(date).format('H:mm') + ' - Ngày mai' 
          }

          return moment(date).format('H:mm - DD/MM/YYYY')
        }
    },
}
