<template>
  <v-simple-table class="table-layout">
    <template v-if="tab < 3"  v-slot:default>
      <thead>
        <tr>
          <th v-show="showSTT">#</th>
          <th>Người thực hiện</th>
          <th v-if="tab == 2">Đơn hàng</th>
          <th>Thời gian</th>
          <th>Mô tả</th>
          <th style="min-width: 70px" v-if="tab == 0">Thao tác</th>
        </tr>
      </thead>
      <tbody v-for="(contactHistory, index) in contactHistorys" :key="contactHistory.id" @click="editAppointment(contactHistory)" style="cursor: pointer">
        <tr>
          <td v-show="showSTT">{{index + 1}}</td>
          <td>{{_.get(contactHistory, 'creatorFullName', '')}}</td>
          <td v-if="tab == 2">{{ _.get(contactHistory, 'order.getfly_id', '') }}</td>
          <td>{{ formatDate(_.get(contactHistory, 'createdAt', '')) }} {{formatHour(_.get(contactHistory, 'createdAt', ''))}}</td>
          <td>
            <span v-if="!contactHistorys[index].editable">{{_.get(contactHistory, 'description', '')}}</span>
            <textarea v-else v-model="contactHistory.description" @change="updateContactHistory(contactHistory)" style="width: 100%; height: 80%; margin-top: 10px; padding-left: 5px"></textarea>
          </td>
          <td v-if="tab == 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  size="16"
                  width="20"
                  height="20"
                  class="mr-1"
                  icon
                  small
                  color="primary"
                  v-on="on"
                  @click="showEditForm(contactHistory, index)"
                >
                  <v-icon size="16">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Sửa</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :style="checkContactHistory(contactHistory) ? 'display: none' : ''"
                  size="16"
                  width="20"
                  height="20"
                  class="mr-1"
                  icon
                  small
                  color="error"
                  @click="deleteContactHistory(contactHistory)"
                  v-on="on"
                >
                  <v-icon size="16">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Xóa lịch sử</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>

    <div>
      <v-row v-for="item in orderList" class="border-item mr-3 ml-8 mb-3">
        <v-col cols="7" class="text-left font-weight-bold">{{ item.code }} - {{ formatPrice(item.totalPrice) }} / {{ item.paymentType}} / {{_.get(item, 'contactStatus.type', '')}}-{{_.get(item, 'contactStatus.name', '')}}</v-col>
        <v-col cols="5" class="text-right font-weight-bold">Sale: {{ item.user && item.user.fullName ? item.user.fullName : '' }}</v-col>

        <v-row v-for="product in item.products" class="px-2 pb-1">
          <v-col cols="4" class="pl-0 py-0">
            <v-row>
              <v-col cols="3" class="py-0 pr-0 text-right">
                <v-icon class="pr-1" color="green">mdi-book-open-variant</v-icon>
              </v-col>
              <v-col cols="9" class="py-0 text-left">{{ product.name }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="1" class="pl-0 py-0">{{ product.quantity }}</v-col>
          <v-col cols="2" class="pl-0 py-0">{{ formatPrice(product.price) }}</v-col>
          <v-col cols="3" class="pl-0 py-0">{{ product.childName }}</v-col>
          <v-col cols="2" class="pl-0 py-0">{{ formatDate(item.createdAt) }}</v-col>
        </v-row>

        <v-row v-for="combo in item.combos" class="px-2 pb-1">
          <v-col cols="4" class="pl-0 py-0">
            <v-row>
              <v-col cols="3" class="py-0 pr-0 text-right">
                <v-icon class="pr-1" color="green">mdi-book-open-variant</v-icon>
              </v-col>
              <v-col cols="9" class="py-0 text-left">{{ combo.name }}</v-col>
            </v-row>
          </v-col>

          <v-col cols="1" class="pl-0 py-0">{{ combo.quantity }}</v-col>
          <v-col cols="2" class="pl-0 py-0">{{ formatPrice(combo.price) }}</v-col>
          <v-col cols="3" class="pl-0 py-0">{{ combo.childName }}</v-col>
          <v-col cols="2" class="pl-0 py-0">{{ formatDate(item.createdAt) }}</v-col>
        </v-row>

      </v-row>
    </div>

  </v-simple-table>
</template>
<script>
export default {
  props: {
    contactHistorys: {
      type: Array,
      default: () => []
    },
    showSTT: {
       type: Boolean,
       default: true,
    },
    currentUserId: {
      type: String,
      default: ''
    },
    tab: {
      type: Number,
      default: 0
    },
    orderList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    editAppointment (contactHistory) {
      if (contactHistory.action === "APPOINTMENT" && contactHistory.dataId != null) {
        Bus.$emit('appointment-show-form-from-appointment', {id: contactHistory.dataId})
      }
    },
    deleteContactHistory (contactHistory) {
      this.$emit('deleteContactHistory', contactHistory)
    },
    checkContactHistory (contactHistory) {
      if (contactHistory.action === "NOTE" && contactHistory.createdBy == this.currentUserId) {
        return false
      }
      return true
    },
    showEditForm(contactHistory, index) {
      this.editDesc = _.get(contactHistory, "description", "")
      this.contactHistorys[index].editable = true
    },
    updateContactHistory(contactHistory) {
      this.$emit('updateContactHistory', contactHistory)
    }
  }
};
</script>
<style lang="scss" scoped>
.table-layout .v-data-table__wrapper thead th {
  position: sticky;
  top: 0px;
  z-index: 9;
}

.border-item {
  border: #9C9C9C 1px solid;
  border-radius: 5px;
}
</style>